import React from 'react'

import Layout from '../components/layout'
// import SEO from '../components/seo'

import img1 from "../images/scs1.png";
import img2 from "../images/scs2.png";
import img3 from "../images/scs3.png";

const PortfolioPage = () => (
  <Layout>
    {/* <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `portfolio`]}
    /> */}
    <div className="portfolio-header">
      <span className="post-heading">Sydney Catholic Schools</span>
      <h5>School Websites</h5>
      <p><strong>Stage 1: </strong>Design and development of a high-fidelity website prototype built with WordPress Multisite within Microsoft Azure Cloud Computing Platform, working collaboratively within a cross-functional project team and iterating in workshops with school principals to test that the design, content and structure were aligned with the brand strategy.</p>
      <p><strong>Stage 2: </strong>Development of the revised high-fidelity website prototype into a fully functional Wordpress theme aligned with the business and technical requirements.</p>
    </div>

    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Theme customised for Primary Schools</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Theme customised for Secondary Schools</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Theme customised for Dark Theme Version</figcaption>
        <img src={img3} alt=""/>
      </figure>
    </div>

    <div className="portfolio-footer">
      {/* <a className="btn btn-primary" href="https://www.google.com">View Project</a> */}
    </div>

  </Layout>
)

export default PortfolioPage
